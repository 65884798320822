import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';

@Component({
  selector: 'msa-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrl: './status-pill.component.css',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, SafeTranslatePipe]
})
export class StatusPillComponent {
  /**
   * This field will always override the automatically inferred translation text
   */
  public text = input<string>();

  /**
   * CSS class for the pill text color
   */
  public colorClass = input<string | undefined>();

  /**
   * CSS class for the pill background color
   */
  public backgroundClass = input<string>();
}
