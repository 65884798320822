<ng-template let-formFieldId="id">
  <div
    class="mat-mdc-autocomplete-panel mdc-menu-surface mdc-menu-surface--open"
    role="listbox"
    [id]="id"
    [ngClass]="_classList"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="_getPanelAriaLabelledby(formFieldId)"
    [@panelAnimation]="isOpen ? 'visible' : 'hidden'"
    (@panelAnimation.done)="_animationDone.next($event)"
    #panel>
    <ng-content></ng-content>
  </div>
</ng-template>
