import {
  BankAccountChangeDto,
  EmailDto,
  EmergencyContactUpdateDto,
  LanguageSkillUpdateDto,
  OccupationDto,
  PhoneDto,
  PreferencesDto,
  SwissPassDto
} from 'projects/person-data/src/app/core/api/generated/msa-person-data';

export class FetchPersonData {
  static readonly type = '[personData] Fetch all person data info';
}

export class FetchPendingRequests {
  static readonly type = '[personData] Fetch person pending requests';
}

export class FetchPersonPreferences {
  static readonly type = '[personData] Fetch person notification preferences';
}

export class UpdateAndFetchPersonPreferences {
  static readonly type = '[personData] Update and fetch person notification preferences';

  constructor(public payload: PreferencesDto) {}
}

export class UpdateBankAccountData {
  static readonly type = '[personData] Update banking information';

  constructor(public payload: BankAccountChangeDto) {}
}

export class UpdateEmails {
  static readonly type = '[personData] Update emails';

  constructor(public payload: Array<EmailDto>) {}
}

export class UpdateEmergencyContacts {
  static readonly type = '[personData] Update emergency contacts';

  constructor(public payload: Array<EmergencyContactUpdateDto>) {}
}

export class UpdatePhones {
  static readonly type = '[personData] Update phones';

  constructor(public payload: Array<PhoneDto>) {}
}

export class UpdateOccupation {
  static readonly type = '[personData] Update occupation';

  constructor(public payload: OccupationDto) {}
}

export class FetchMilitaryHistoryData {
  static readonly type = '[personData] Fetch military history data';
}

export class UpdateLanguageSkills {
  static readonly type = '[personData] Update language skills';

  constructor(public payload: Array<LanguageSkillUpdateDto>) {}
}

export class ResetStatusCode {
  static readonly type = '[personData] Reset status code';
}

export class FetchSwissPass {
  static readonly type = '[personData] Fetch swiss pass data';
}

export class UpdateSwissPass {
  static readonly type = '[personData] Update swiss pass data';

  constructor(public payload: SwissPassDto) {}
}

export class DeleteSwissPass {
  static readonly type = '[personData] Delete swiss pass data';
}

export class FetchCurrentMilitaryData {
  static readonly type = '[personData] Fetch current military data';
}

export class FetchServiceDaysData {
  static readonly type = '[personData] Fetch service days data';
}
