import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
  input
} from '@angular/core';

@Directive({
  selector: '[appPopoverTrigger]',
  standalone: true
})
export class PopoverDirective implements OnInit {
  public appPopoverTrigger = input.required<TemplateRef<unknown>>();

  private overlayRef!: OverlayRef;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private vcr: ViewContainerRef,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.createOverlay();
  }

  private createOverlay(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: false
    });

    this.overlayRef.backdropClick().subscribe(() => this.detachOverlay());
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const overlayRefInjector = Injector.create({
        providers: [{provide: OverlayRef, useValue: this.overlayRef}],
        parent: this.injector
      });

      const portal = new TemplatePortal(this.appPopoverTrigger(), this.vcr, undefined, overlayRefInjector);
      this.overlayRef.attach(portal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  @HostListener('click')
  toggle() {
    this.overlayRef && this.overlayRef.hasAttached() ? this.detachOverlay() : this.attachOverlay();
  }
}

@Directive({
  selector: '[appPopoverClose]',
  standalone: true
})
export class PopoverCloseDirective {
  private overlayRef = inject(OverlayRef);

  @HostListener('click')
  onClick() {
    this.overlayRef.detach();
  }
}
