<cdk-accordion title="">
  <cdk-accordion-item role="button" tabindex="0">
    <div
      class="tw-flex tw-flex-row tw-items-center tw-gap-6 tw-border-t tw-border-t-black-light-4 tw-pb-18 tw-pt-18 d:tw-gap-12"
      (click)="this.toggle()"
    >
      <mat-icon class="tw-size-24">{{ expanded ? "remove" : "add" }}</mat-icon>
      @if (title()) {
        <h3 class="msa-text-h3">{{ title() | translate }}</h3>
      }

      <ng-content select="[header]" />
    </div>

    <div
      class="tw-cursor-auto"
      [@slideInOut]="getExpandedState()"
      [class.expanded]="expandedChange | async"
      role="region"
    >
      <div class="tw-h-auto tw-pb-15 tw-ps-30 d:tw-pb-18 d:tw-ps-[36px]">
        <ng-content />
      </div>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
