import {CdkAccordionItem, CdkAccordionModule} from '@angular/cdk/accordion';
import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {MatExpansionPanelState} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {provideAnimations} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {AccordionSlide} from '@shared/shared-module/animations/animations';

@Component({
  selector: 'msa-collapsible',
  templateUrl: './msa-collapsible.component.html',
  standalone: true,
  animations: [AccordionSlide],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, MatIconModule, CdkAccordionModule, AsyncPipe],
  providers: [provideAnimations()]
})
export class MsaCollapsibleComponent extends CdkAccordionItem {
  public title = input.required<string>();

  getExpandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }
}
