import {animate, group, state, style, transition, trigger} from '@angular/animations';

export const AccordionSlide = [
  trigger('slideInOut', [
    state(
      'expanded',
      style({
        'max-height': '*',
        opacity: '1',
        display: 'block'
      })
    ),
    state(
      'collapsed',
      style({
        'max-height': '0px',
        opacity: '0',
        display: 'none'
      })
    ),
    transition('expanded => collapsed', [
      group([
        animate(
          '100ms ease-in-out',
          style({
            opacity: '0'
          })
        ),
        animate(
          '100ms ease-in-out',
          style({
            'max-height': '0px'
          })
        ),
        animate(
          '100ms ease-in-out',
          style({
            visibility: 'hidden'
          })
        )
      ])
    ]),
    transition('collapsed => expanded', [
      group([
        animate(
          '200ms ease-in-out',
          style({
            visibility: 'visible'
          })
        ),
        animate(
          '100ms ease-in-out',
          style({
            height: '*'
          })
        ),
        animate(
          '100ms ease-in-out',
          style({
            opacity: '1'
          })
        )
      ])
    ])
  ])
];
